import { useState, useEffect } from "react"

import { useUser } from "~config/context-providers/user-provider"
import { useMyOutfitters } from "~utils/hooks/use-outfitter"
import type { MinimalOutfitterFieldsFragment } from "~graphql/generated/graphql"
import { useSearchOutfittersQuery } from "~graphql/generated/graphql"
import type { OutfitterSelectProps } from "~components/shared/outfitter-select/outfitter-select"

export function useOutfitterSelectOptions({
    selectedOutfitter,
    shouldDefaultToFirstOutfitter,
    shouldAllowOutfittersAdminSearch,
    onChange,
}: OutfitterSelectProps) {
    const SEARCH_QUERY_SIZE = 5

    const { isAdmin } = useUser()

    const {
        outfitters: myOutfitters,
        isLoading: isLoadingMyOutfitters,
        defaultOutfitter,
    } = useMyOutfitters()

    const [searchText, setSearchText] = useState("")

    const shouldSearchForOutfitters =
        isAdmin && shouldAllowOutfittersAdminSearch

    // TODO: Switch to using useSearchOutfitters in hooks folder.
    const { data, loading: isLoadingSearchOutfitters } =
        useSearchOutfittersQuery({
            variables: { text: searchText, size: SEARCH_QUERY_SIZE, page: 0 },
            skip: !shouldSearchForOutfitters,
        })

    // Default to first outfitter
    useEffect(() => {
        if (
            !shouldDefaultToFirstOutfitter ||
            selectedOutfitter ||
            !defaultOutfitter
        ) {
            return
        }

        onChange(defaultOutfitter)
    }, [defaultOutfitter, shouldDefaultToFirstOutfitter])

    const searchOutfitters = data?.searchOutfitters.results || []

    // Filter out the searched outfitters that are already in my outfitters.
    const filteredSearchOutfitters = searchOutfitters.filter(
        (outfitter) =>
            !myOutfitters.some((myOutfitter) => myOutfitter.id === outfitter.id)
    )

    // Filter out the selected outfitter.
    const outfitterOptions = [
        ...myOutfitters,
        ...filteredSearchOutfitters,
    ].filter((outfitter) => outfitter.id !== selectedOutfitter?.id)

    return {
        searchText,
        setSearchText,
        outfitterOptions,
        isLoadingMyOutfitters,
        isLoadingSearchOutfitters,
        shouldSearchForOutfitters,
    }
}

export type OutfitterSelectOptionType = MinimalOutfitterFieldsFragment
