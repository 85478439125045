import {
    FaHome,
    FaCalendarWeek,
    FaList,
    FaCheckSquare,
    FaUser,
    FaInbox,
    FaPaperPlane,
    FaChartBar,
    FaPercent,
    FaFastForward,
    FaFeather,
    FaEdit,
    FaUsers,
    FaCoffee,
    FaUserPlus,
} from "react-icons/fa"
import type { IconType } from "react-icons"

import { FeatureKey } from "~graphql/generated/graphql"
import { PAGES, ADMIN_PAGES, ROUTES } from "~config/constants"
import { useFeature } from "~utils/hooks/use-feature-access"
import { usePermissions } from "~components/shared/permissions/permissions-provider"
import { useUser } from "~config/context-providers/user-provider"

export function useSidebarItemGroups() {
    const { shouldShowActions } = usePermissions()
    const mainItems = useMainItems()
    const adminItems = useAdminItems()
    const filteredMainItems = mainItems.filter(
        (item) => !item.requiresActionPermission || shouldShowActions
    )

    return [filteredMainItems, adminItems]
}

function useMainItems(): SidebarItem[] {
    const { isAdminNotImpersonating } = useUser()

    const { isEnabled: isMessagingEnabled } = useFeature(FeatureKey.Messaging)
    const { isEnabled: isBookingInviteEnabled } = useFeature(
        FeatureKey.BookingInvites
    )
    const { isEnabled: isCouponsEnabled } = useFeature(FeatureKey.Coupons)
    const { isEnabled: isBookingsCalendarEnabled } = useFeature(
        FeatureKey.ViewBookingsInCalendar
    )

    const areAllReportsPaywalled = useAreAllReportsPaywalled()

    const calendarRoute = isBookingsCalendarEnabled
        ? ROUTES.CALENDAR
        : ROUTES.AVAILABILITY_CALENDAR

    return [
        {
            name: PAGES.HOME.name,
            label: PAGES.HOME.label,
            link: ROUTES.HOME,
            icon: FaHome,
        },
        {
            name: PAGES.CALENDAR.name,
            label: PAGES.CALENDAR.label,
            link: calendarRoute,
            icon: FaCalendarWeek,
        },
        {
            name: PAGES.BOOKINGS.name,
            label: PAGES.BOOKINGS.label,
            link: ROUTES.BOOKINGS,
            icon: FaCheckSquare,
        },
        ...(isAdminNotImpersonating
            ? []
            : [
                  {
                      name: PAGES.MY_OUTFITTER.name,
                      label: PAGES.MY_OUTFITTER.label,
                      link: ROUTES.MY_OUTFITTER,
                      icon: FaUser,
                  },
              ]),
        {
            name: PAGES.LISTINGS.name,
            label: PAGES.LISTINGS.label,
            link: ROUTES.MANAGE_LISTINGS,
            icon: FaList,
        },
        ...(isAdminNotImpersonating
            ? []
            : [
                  {
                      name: PAGES.INBOX.name,
                      label: PAGES.INBOX.label,
                      link: ROUTES.INBOX,
                      icon: FaInbox,
                      requiresActionPermission: true,
                      isPaywalled: !isMessagingEnabled,
                  },
              ]),
        {
            name: PAGES.QUOTES.name,
            label: PAGES.QUOTES.label,
            link: ROUTES.QUOTES,
            icon: FaPaperPlane,
            isPaywalled: !isBookingInviteEnabled,
        },
        {
            name: PAGES.REPORTS.name,
            label: PAGES.REPORTS.label,
            link: ROUTES.REPORTING,
            icon: FaChartBar,
            requiresActionPermission: true,
            isPaywalled: areAllReportsPaywalled,
        },
        {
            name: PAGES.REFERRALS.name,
            label: PAGES.REFERRALS.label,
            link: ROUTES.REFERRALS,
            icon: FaFastForward,
        },
        {
            name: PAGES.COUPONS.name,
            label: PAGES.COUPONS.label,
            link: ROUTES.COUPONS,
            icon: FaPercent,
            isPaywalled: !isCouponsEnabled,
        },
    ]
}

function useAdminItems(): SidebarItem[] {
    const { isAdminNotImpersonating } = useUser()

    const shouldShowAdminItems = isAdminNotImpersonating

    if (!shouldShowAdminItems) return []

    return [
        {
            name: ADMIN_PAGES.ONBOARDINGS.name,
            label: ADMIN_PAGES.ONBOARDINGS.label,
            link: ROUTES.ONBOARDINGS,
            icon: FaUserPlus,
        },
        {
            name: ADMIN_PAGES.OUTFITTERS.name,
            label: ADMIN_PAGES.OUTFITTERS.label,
            link: ROUTES.OUTFITTERS,
            icon: FaUsers,
        },
        {
            name: ADMIN_PAGES.AMENITIES.name,
            label: ADMIN_PAGES.AMENITIES.label,
            link: ROUTES.AMENITIES,
            icon: FaCoffee,
        },
        {
            name: ADMIN_PAGES.SPECIES.name,
            label: ADMIN_PAGES.SPECIES.label,
            link: ROUTES.SPECIES,
            icon: FaFeather,
        },
        {
            name: ADMIN_PAGES.LICENSES.name,
            label: ADMIN_PAGES.LICENSES.label,
            link: ROUTES.LICENSES,
            icon: FaEdit,
        },
    ]
}

function useAreAllReportsPaywalled() {
    const { isEnabled: isBasicEnabled } = useFeature(FeatureKey.ReportingBasic)
    const { isEnabled: isBusinessPerformanceEnabled } = useFeature(
        FeatureKey.ReportingBusinessPerformance
    )
    const { isEnabled: isPaymentsEnabled } = useFeature(
        FeatureKey.ReportingPayments
    )
    const { isEnabled: isPayoutsEnabled } = useFeature(
        FeatureKey.ReportingPayouts
    )
    const { isEnabled: isUnpaidBalancesEnabled } = useFeature(
        FeatureKey.ReportingUnpaidBalances
    )

    return (
        !isBasicEnabled &&
        !isBusinessPerformanceEnabled &&
        !isPaymentsEnabled &&
        !isPayoutsEnabled &&
        !isUnpaidBalancesEnabled
    )
}

type SidebarItem = {
    name: string
    label: string
    link: string
    requiresActionPermission?: boolean
    icon: IconType
    isPaywalled?: boolean
}
