import type { ApolloError } from "@apollo/client"
import React, {
    useContext,
    createContext,
    type PropsWithChildren,
    useEffect,
} from "react"

import type { MinimalOutfitterFieldsFragment } from "~graphql/generated/graphql"
import useMyOutfitters from "~utils/hooks/use-my-outfitters"
import { usePersistedOutfitter } from "~utils/hooks/use-persisted-selected-global-outfitter"

export type OutfitterTypeForContext =
    | MinimalOutfitterFieldsFragment
    | undefined
    | null

type OutfitterContextType = {
    outfitter: OutfitterTypeForContext
    outfitterId: string | undefined | null
    setOutfitter: (outfitter: OutfitterTypeForContext) => void
    setOutfitterById: (id: string | undefined | null) => void
    outfitters: MinimalOutfitterFieldsFragment[]
    isLoading: boolean
    error?: ApolloError | Error | null
}

const OutfitterContext = createContext<OutfitterContextType>({
    outfitter: undefined,
    outfitterId: undefined,
    setOutfitter: () => {},
    setOutfitterById: () => {},
    outfitters: [],
    isLoading: false,
    error: null,
})

export function OutfitterProvider({ children }: PropsWithChildren) {
    const contextValue = useGetOutfitterValues()

    return (
        <OutfitterContext.Provider value={contextValue}>
            {children}
        </OutfitterContext.Provider>
    )
}

function useGetOutfitterValues(): OutfitterContextType {
    const { outfitters, defaultOutfitter, isLoading, error } = useMyOutfitters()
    const { outfitter, setOutfitter } = usePersistedOutfitter(defaultOutfitter)

    function setOutfitterById(newOutfitterId: string | undefined | null) {
        if (!newOutfitterId) {
            setOutfitter(null)
            return
        }

        const newOutfitter = outfitters.find(({ id }) => id === newOutfitterId)

        setOutfitter(newOutfitter)
    }

    useEffect(() => {
        if (!outfitter && defaultOutfitter) {
            setOutfitter(defaultOutfitter)
        }
    }, [setOutfitter, outfitter, defaultOutfitter])

    return {
        outfitter,
        outfitterId: outfitter?.id,
        setOutfitter,
        setOutfitterById,
        outfitters,
        isLoading,
        error,
    }
}

export const useOutfitterContext = () => useContext(OutfitterContext)
