import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Icon, Center } from "@chakra-ui/react"
import { componentStyles } from "@mallardbay/lib-react-components"
import type { IconType } from "react-icons"

import SidebarPaywallIndicator from "./sidebar-paywall-indicator"

interface Props {
    readonly icon?: IconType
    readonly isActiveItem?: boolean
    readonly isPaywalled?: boolean
    readonly isSidebarCollapsed?: boolean
}

export default function SidebarIcon({
    icon,
    isActiveItem,
    isPaywalled,
    isSidebarCollapsed,
}: Props) {
    const styles = useStyleProps({ isActiveItem })

    if (isPaywalled && isSidebarCollapsed) {
        return <SidebarPaywallIndicator />
    }

    return (
        <Center sx={styles.iconContainer}>
            <Icon {...styles.icon} as={icon} />
        </Center>
    )
}

function useStyleProps({ isActiveItem }: { isActiveItem?: boolean }) {
    const color = isActiveItem ? "brand.400" : undefined

    return componentStyles({
        iconContainer: {
            marginX: 3,
            padding: "8px",
            borderRadius: "lg",
        },
        icon: {
            height: "18px",
            width: "18px",
            color,
            opacity: isActiveItem ? 1 : 0.8,
        },
    })
}
