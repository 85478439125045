// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { MenuList, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { FiLogOut, FiUsers } from "react-icons/fi"

import { useAuth } from "~utils/hooks/use-auth"
import ViewingAsDisplay from "~components/shared/profile-menu/viewing-as-display"
import ProfileMenuDivider from "~components/shared/profile-menu/profile-menu-divider"
import IconMenuItem from "~components/shared/icon-menu-item"
import ConditionalBox from "~components/shared/containers/conditional-box"
import { PROFILE_MENU_LABELS } from "~config/constants"
import ImpersonationModal from "~components/shared/auth/impersonation-modal"
import { useUser } from "~config/context-providers/user-provider"
import { useStopImpersonationMutation } from "~graphql/generated/graphql"
import AccountSettingsSection from "~components/shared/profile-menu/account-settings-section"
import HubspotMenuItem from "~components/shared/profile-menu/hubspot-menu-item"

interface ProfileMenuProps {
    showAccountSettings?: boolean
}

export default function ProfileMenu({
    showAccountSettings,
}: Readonly<ProfileMenuProps>) {
    const { isAdmin, isImpersonating } = useUser()

    const {
        isOpen: isImpersonationOpen,
        onOpen: onImpersonationOpen,
        onClose: onImpersonationClose,
    } = useDisclosure()
    const handleLogout = useHandleLogout()

    return (
        <>
            <MenuList shadow="xl" rounded="xl" width="250px" pos="relative">
                <ViewingAsDisplay />
                <ConditionalBox showIf={isAdmin || isImpersonating}>
                    <ProfileMenuDivider />
                    <IconMenuItem
                        onClick={onImpersonationOpen}
                        label={PROFILE_MENU_LABELS.VIEW_AS_LABEL}
                        icon={FiUsers}
                    />
                </ConditionalBox>
                <ProfileMenuDivider />
                <ProfileMenuDivider />
                <HubspotMenuItem />
                {showAccountSettings && <AccountSettingsSection />}
                <IconMenuItem
                    onClick={handleLogout}
                    label={PROFILE_MENU_LABELS.LOG_OUT_LABEL}
                    icon={FiLogOut}
                />
            </MenuList>
            <ImpersonationModal
                isOpen={isImpersonationOpen}
                onClose={onImpersonationClose}
            />
        </>
    )
}

function useHandleLogout() {
    const [stopImpersonation] = useStopImpersonationMutation()
    const { logout } = useAuth()

    return () => {
        void stopImpersonation()
        void logout()
    }
}
