import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { MenuItem } from "@chakra-ui/react"

import OutfitterSelectOptionContent from "~components/shared/outfitter-select/outfitter-select-option-content"
import type { MinimalOutfitterFieldsFragment } from "~graphql/generated/graphql"

interface Props {
    readonly outfitter: MinimalOutfitterFieldsFragment
    readonly onClick?: () => void
}

export default function OutfitterSelectOption({ outfitter, onClick }: Props) {
    return (
        <MenuItem onClick={onClick} data-testid={outfitter.id}>
            <OutfitterSelectOptionContent outfitter={outfitter} />
        </MenuItem>
    )
}
