// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { TooltipProps } from "@chakra-ui/react"
import { componentStyles } from "@mallardbay/lib-react-components"

export function useSharedStyles({ isActiveItem }: { isActiveItem?: boolean }) {
    const color = isActiveItem ? "brand.400" : undefined

    return componentStyles({
        activeBar: {
            width: "4px",
            height: "42px",
            left: "0",
            position: "absolute",
            bgColor: "brand.400",
        },
        label: {
            paddingTop: "2px",
            fontSize: "14px",
            fontWeight: isActiveItem ? 700 : 500,
            color,
            transition: "width .3s",
            noOfLines: 1,
        },
    })
}

export function useTooltipProps(): Partial<TooltipProps> {
    return {
        hasArrow: true,
        placement: "right",
        arrowSize: 8,
    }
}
