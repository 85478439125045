import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Avatar, Text, HStack } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { BoxProps, AvatarProps } from "@chakra-ui/react"

import type { OutfitterSelectOptionType } from "~components/shared/outfitter-select/outfitter-select-helpers"

interface Props {
    readonly outfitter: OutfitterSelectOptionType
}

export default function OutfitterSelectOptionContent({ outfitter }: Props) {
    const styleProps = useStyleProps()

    return (
        <HStack {...styleProps.root}>
            <Avatar
                {...styleProps.avatar}
                name={outfitter.name}
                src={outfitter.avatar?.url}
            />
            <Text noOfLines={1}>{outfitter.name}</Text>
        </HStack>
    )
}

function useStyleProps() {
    const avatarSize = "24px"

    const root: BoxProps = {
        maxW: "300px",
    }

    const avatar: AvatarProps = {
        width: avatarSize,
        height: avatarSize,
        marginRight: "10px",
    }

    return { root, avatar }
}
