import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { MenuListProps } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Menu, MenuList, MenuItem } from "@chakra-ui/react"

import OutfitterSelectOption from "~components/shared/outfitter-select/outfitter-select-option"
import OutfitterSelectMenuButton from "~components/shared/outfitter-select/outfitter-select-menu-button"
import OutfitterSelectSearchInput from "~components/shared/outfitter-select/outfitter-select-search-input"
import { useOutfitterSelectOptions } from "~components/shared/outfitter-select/outfitter-select-helpers"
import type { OutfitterSelectOptionType } from "~components/shared/outfitter-select/outfitter-select-helpers"
import COPY from "~config/copy-constants"

export interface OutfitterSelectProps {
    readonly shouldDefaultToFirstOutfitter?: boolean
    readonly shouldAllowOutfittersAdminSearch?: boolean
    readonly shouldAllowAllOutfittersOption?: boolean
    readonly selectedOutfitter: OutfitterSelectOptionType | null
    readonly onChange: (outfitter: OutfitterSelectOptionType | null) => void
    readonly isDisabled?: boolean
}

export default function OutfitterSelect({
    shouldDefaultToFirstOutfitter = true,
    shouldAllowOutfittersAdminSearch = false,
    shouldAllowAllOutfittersOption = false,
    selectedOutfitter,
    onChange,
    isDisabled,
}: OutfitterSelectProps) {
    const styleProps = useStyleProps()

    const {
        searchText,
        setSearchText,
        outfitterOptions,
        isLoadingMyOutfitters,
        isLoadingSearchOutfitters,
        shouldSearchForOutfitters,
    } = useOutfitterSelectOptions({
        selectedOutfitter,
        shouldDefaultToFirstOutfitter,
        shouldAllowOutfittersAdminSearch,
        onChange,
    })

    if (
        !outfitterOptions.length &&
        !isLoadingMyOutfitters &&
        !isLoadingSearchOutfitters &&
        !shouldSearchForOutfitters // Don't hide the selector, since searching can return no results for outfitterOptions
    ) {
        return null
    }

    return (
        <Menu>
            <OutfitterSelectMenuButton
                selectedOutfitter={selectedOutfitter}
                isLoading={isLoadingMyOutfitters}
                shouldAllowAllOutfittersOption={shouldAllowAllOutfittersOption}
                isDisabled={isDisabled}
            />
            <MenuList {...styleProps.menuList}>
                {shouldSearchForOutfitters && (
                    <OutfitterSelectSearchInput
                        searchText={searchText}
                        onSearchTextChange={setSearchText}
                        isSearching={isLoadingSearchOutfitters}
                    />
                )}
                {shouldAllowAllOutfittersOption && (
                    <MenuItem onClick={() => onChange(null)}>
                        {COPY.ALL_OUTFITTERS}
                    </MenuItem>
                )}
                {outfitterOptions.map((outfitter) => (
                    <OutfitterSelectOption
                        key={outfitter.id}
                        outfitter={outfitter}
                        onClick={() => onChange(outfitter)}
                    />
                ))}
            </MenuList>
        </Menu>
    )
}

function useStyleProps() {
    const menuList: MenuListProps = {
        rounded: "lg",
    }

    return { menuList }
}
