import React, { type PropsWithChildren } from "react"

import { useOutfitterContext } from "~config/context-providers/outfitter-provider"
import PermissionsProvider from "~components/shared/permissions/permissions-provider"

export default function SelectedOutfitterPermissionsProvider({
    children,
}: PropsWithChildren) {
    const { outfitter } = useOutfitterContext()
    return (
        <PermissionsProvider outfitterId={outfitter?.id}>
            {children}
        </PermissionsProvider>
    )
}
