import React from "react"

import { useOutfitterContext } from "~config/context-providers/outfitter-provider"
import { useUser } from "~config/context-providers/user-provider"

import OutfitterSelect from "./outfitter-select"

export default function OutfitterSelectGlobal() {
    const { outfitter, setOutfitter } = useOutfitterContext()

    const { isAdminNotImpersonating } = useUser()
    const extraProps = isAdminNotImpersonating
        ? {
              shouldDefaultToFirstOutfitter: false,
              shouldAllowAllOutfittersOption: true,
              shouldAllowOutfittersAdminSearch: true,
          }
        : {}

    return (
        <OutfitterSelect
            selectedOutfitter={outfitter ?? null}
            onChange={setOutfitter}
            {...extraProps}
        />
    )
}
