import React from "react"
import { BiLockAlt } from "react-icons/bi"

import { TEST_IDS } from "~config/constants"

interface Props {
    readonly size?: number
}

export default function PaywallIcon({ size = 18 }: Props) {
    return <BiLockAlt data-testid={TEST_IDS.PAYWALL_ICON} size={size} />
}
