import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { MenuButton, Button, type ButtonProps } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

import OutfitterSelectOptionContent from "~components/shared/outfitter-select/outfitter-select-option-content"
import COPY from "~config/copy-constants"
import { TEST_IDS } from "~config/constants"
import type { OutfitterSelectOptionType } from "~components/shared/outfitter-select/outfitter-select-helpers"

interface Props {
    readonly selectedOutfitter: OutfitterSelectOptionType | null
    readonly isLoading?: boolean
    readonly isDisabled?: boolean
    readonly shouldAllowAllOutfittersOption?: boolean
}

export default function OutfitterSelectMenuButton({
    selectedOutfitter,
    isLoading,
    isDisabled,
    shouldAllowAllOutfittersOption,
}: Props) {
    const styleProps = useStyleProps()

    const placeholder = getPlaceholder({
        shouldAllowAllOutfittersOption,
        isLoading,
    })

    return (
        <MenuButton
            {...styleProps.root}
            as={Button}
            rightIcon={<ChevronDownIcon fontSize={20} />}
            isDisabled={isLoading || isDisabled}
            isLoading={isLoading}
            data-testid={TEST_IDS.OUTFITTER_SELECT}
        >
            {selectedOutfitter && (
                <OutfitterSelectOptionContent outfitter={selectedOutfitter} />
            )}
            {!selectedOutfitter && <span>{placeholder}</span>}
        </MenuButton>
    )
}

function useStyleProps() {
    const root: ButtonProps = {
        width: "100%",
        padding: "0 16px",
        variant: "outline",
    }

    return { root }
}

function getPlaceholder({
    shouldAllowAllOutfittersOption,
    isLoading,
}: {
    shouldAllowAllOutfittersOption?: boolean
    isLoading?: boolean
}) {
    if (isLoading) return COPY.LOADING

    return shouldAllowAllOutfittersOption
        ? COPY.ALL_OUTFITTERS
        : COPY.SELECT_OUTFITTER
}
