import { useAuth } from "~utils/hooks/use-auth"
import { useGetUserQuery } from "~graphql/generated/graphql"

export function useUser() {
    const {
        isLoading: isAuthLoading,
        error: authError,
        accessToken,
    } = useAuth()

    const {
        loading: isUserLoading,
        data,
        error: userError,
    } = useGetUserQuery({
        skip: !accessToken,
        variables: {},
    })

    const isLoading = isAuthLoading || isUserLoading
    const error = authError || userError
    const user = data?.user

    // Some of the flags above also exist in `user-provider`.
    // Can we move them all here to be used outside of the provider?
    const isAdmin = Boolean(user?.is_admin)
    const isImpersonating = Boolean(
        user?.impersonation_details?.is_impersonated
    )
    const isAdminNotImpersonating = isAdmin && !isImpersonating

    return { user, isLoading, error, isAdminNotImpersonating }
}
