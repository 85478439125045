import { useLocalStorage } from "usehooks-ts"

import { LOCAL_STORAGE_KEYS } from "~config/constants"
import type { OutfitterTypeForContext } from "~config/context-providers/outfitter-provider"

export function usePersistedOutfitter(
    initialOutfitter: OutfitterTypeForContext
) {
    const [outfitter, setOutfitter] = useLocalStorage<OutfitterTypeForContext>(
        LOCAL_STORAGE_KEYS.MB_GLOBAL_OUTFITTER,
        initialOutfitter
    )

    return {
        outfitter,
        setOutfitter,
    }
}

export function useClearSelectedOutfitter() {
    const { setOutfitter } = usePersistedOutfitter(null)

    return () => {
        setOutfitter(null)
    }
}
