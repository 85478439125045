import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { HStack, Input, Progress } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { StackProps, ProgressProps, InputProps } from "@chakra-ui/react"
import { FiSearch } from "react-icons/fi"
import { useColors } from "@mallardbay/lib-react-components"

import COPY from "~config/copy-constants"

interface Props {
    readonly searchText: string
    readonly onSearchTextChange: (text: string) => void
    readonly isSearching: boolean
}

export default function OutfitterSelectSearchInput({
    searchText,
    onSearchTextChange,
    isSearching,
}: Props) {
    const styleProps = useStyleProps()

    return (
        <>
            <HStack {...styleProps.container}>
                <FiSearch {...styleProps.icon} />
                <Input
                    value={searchText}
                    onChange={(event) => onSearchTextChange(event.target.value)}
                    {...styleProps.input}
                />
            </HStack>
            <Progress isIndeterminate={isSearching} {...styleProps.divider} />
        </>
    )
}

function useStyleProps() {
    const { brandColorScheme } = useColors()

    const container: StackProps = {
        mx: 3,
    }

    const input: InputProps = {
        variant: "unstyled",
        placeholder: COPY.SEARCH_ALL_OUTFITTERS,
    }

    const divider: ProgressProps = {
        my: 2,
        height: "1px",
        colorScheme: brandColorScheme,
    }

    const icon = {
        fontSize: "20px",
    }

    return { container, input, divider, icon }
}
