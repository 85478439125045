import React from "react"

import Layout from "~components/shared/layout"
import GlobalAuthentication from "~components/shared/global-authentication"

interface Props {
    readonly element: React.ReactNode
}

export default function WrapPageElement({ element }: Props) {
    return (
        <GlobalAuthentication>
            <Layout>{element}</Layout>
        </GlobalAuthentication>
    )
}
