import { objectify } from "radash"
import { useMemo } from "react"

import { useOutfitterContext } from "~config/context-providers/outfitter-provider"
import { useUser } from "~config/context-providers/user-provider"
import type { FeatureKey } from "~graphql/generated/graphql"
import { isNil } from "~utils/helpers/helpers"

function useFeatureAccess() {
    const { outfitter, isLoading, error } = useOutfitterContext()

    const features = useMemo(() => {
        const outfitterFeatures = outfitter?.feature_access.features ?? []
        return objectify(outfitterFeatures, (feature) => feature.feature_key)
    }, [outfitter?.feature_access])

    return {
        features,
        isLoading,
        error,
    }
}

export function useFeature(featureKey: FeatureKey) {
    const { isAdmin } = useUser()
    const { features, error, isLoading } = useFeatureAccess()
    // Admins can access whatever features
    if (isAdmin) {
        return {
            feature_key: featureKey,
            current_count: null,
            limit: null,
            isLimitHit: false,
            isEnabled: true,
            error: null,
            isLoading,
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const feature = features[featureKey] ?? {}
    const isEnabled = Boolean(features[featureKey])
    const limit = feature.limit
    const count = feature.current_count ?? 0
    // If limit is null or undefined, it means there is no limit
    const isLimitHit = isNil(limit) ? false : count >= limit

    return {
        ...feature,
        isEnabled,
        isLimitHit,
        error,
        isLoading,
    }
}
