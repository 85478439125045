import React, { useContext } from "react"
import type { PropsWithStyle } from "@mallardbay/lib-react-components"
import {
    Box,
    componentStyles,
    HorizontalStackSmallGap,
    TextBoldSmall,
    TextSmall,
    TextUnderlinedSmall,
} from "@mallardbay/lib-react-components"

import { UserContext } from "~config/context-providers/user-provider"
import { useAuth } from "~utils/hooks/use-auth"
import { getFullName } from "~utils/helpers/string-helpers"
import COPY from "~config/copy-constants"

export default function ImpersonationBannerContent({ style }: PropsWithStyle) {
    const styles = useStyles()

    const { authUser } = useAuth()
    const { user } = useContext(UserContext)

    const fullName = `${getFullName(user)}`
    const loggedInAsLabel = `${COPY.LOGGED_IN_AS} ${authUser?.name}`
    const loggedInAsEmail = `(${authUser?.email})`
    const viewingAsEmail = `(${user?.email})`

    return (
        <Box style={{ ...styles.root, ...style }}>
            <HorizontalStackSmallGap style={styles.stack}>
                <TextSmall style={styles.text}>{loggedInAsLabel}</TextSmall>
                <TextBoldSmall style={styles.text}>
                    {loggedInAsEmail}
                </TextBoldSmall>
            </HorizontalStackSmallGap>
            <HorizontalStackSmallGap style={styles.stack}>
                <TextSmall>{COPY.NOW_VIEWING}</TextSmall>
                <TextUnderlinedSmall>{fullName}</TextUnderlinedSmall>
                <TextBoldSmall>{viewingAsEmail}</TextBoldSmall>
            </HorizontalStackSmallGap>
        </Box>
    )
}

function useStyles() {
    return componentStyles({
        root: {
            flex: 1,
            whiteSpace: "nowrap",
            noOfLines: 3,
        },
        stack: { gap: 1 },
        text: { alignItems: "center" },
    })
}
