import type { PropsWithChildren } from "react"
import React from "react"
import { useMatch } from "@reach/router"

import WithAuthentication from "~components/shared/with-authentication"
import { ROUTES } from "~config/constants"

export default function GlobalAuthentication({ children }: PropsWithChildren) {
    const teamMemberOnboardingMatch = useMatch(ROUTES.TEAM_MEMBER_ONBOARDING)
    const isSignupDefault = Boolean(teamMemberOnboardingMatch)

    return (
        <WithAuthentication isSignupDefault={isSignupDefault}>
            {children}
        </WithAuthentication>
    )
}
